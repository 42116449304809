.sidebar-container {
    width: 300px;
    height: 100vh;
    /* background: rgba(255,0,0,0.3); */
    /* background: rgba(255,0,0,1); */
    z-index: -1;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-right: 50px;
  }
  
  .top-sidebar {
    display: flex;
  }
  
  .pfp {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* box-shadow: 1px 1px 7px 6px #FFFFFF11; */
  }
  
  .pfp-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .switch {
    width: 256px;
    height: 256px;
    border-radius: 50%;
    background: url('../res/headshot.png');
    alt: "";
  }
  .switch:hover {
    width: 256px;
    height: 256px;
    border-radius: 50%;
    background: url('../res/tongueout.png');
  }

  .title-container {
    margin-bottom: 10px;
  }

  .name-div {
    display: flex;
    justify-content: left;
  }

  .name {
    font-size: 22px;
    font-weight: 700;
  }

  .email {
    width: 76%;
    margin-top: -6px;
    font-weight: 300;
    font-size: 16px;
  }

  .position {
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .navbar {
    margin-top: 20px;
    width: 210px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .link-div {
    margin-bottom: 8px;
  }

  .link {
    color: #5d90ff;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    border-bottom: 1px solid #fafafa;

  }

  .link:hover {
    border-bottom: 1px solid black;
    transition: 0.2s;
  }

  .divider {
    height: 700px;
    width: 1px;
    background: linear-gradient(#fff,#ddd 0,#fff);
    position: absolute;
    top: 48px;
    right: 20px;
}