body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-container {
    width: 300px;
    height: 100vh;
    /* background: rgba(255,0,0,0.3); */
    /* background: rgba(255,0,0,1); */
    z-index: -1;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-right: 50px;
  }
  
  .top-sidebar {
    display: flex;
  }
  
  .pfp {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* box-shadow: 1px 1px 7px 6px #FFFFFF11; */
  }
  
  .pfp-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .switch {
    width: 256px;
    height: 256px;
    border-radius: 50%;
    background: url(../../static/media/headshot.960a3d75.png);
    alt: "";
  }
  .switch:hover {
    width: 256px;
    height: 256px;
    border-radius: 50%;
    background: url(../../static/media/tongueout.b8132cc8.png);
  }

  .title-container {
    margin-bottom: 10px;
  }

  .name-div {
    display: flex;
    justify-content: left;
  }

  .name {
    font-size: 22px;
    font-weight: 700;
  }

  .email {
    width: 76%;
    margin-top: -6px;
    font-weight: 300;
    font-size: 16px;
  }

  .position {
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .navbar {
    margin-top: 20px;
    width: 210px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .link-div {
    margin-bottom: 8px;
  }

  .link {
    color: #5d90ff;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    border-bottom: 1px solid #fafafa;

  }

  .link:hover {
    border-bottom: 1px solid black;
    transition: 0.2s;
  }

  .divider {
    height: 700px;
    width: 1px;
    background: linear-gradient(#fff,#ddd 0,#fff);
    position: absolute;
    top: 48px;
    right: 20px;
}
.body-container { 
    margin-left: 42px;
    margin-top: 30px;
    width: 700px;  
}

.announcements-container {
    margin-top: 30px;
    width: 100;
    background: #fafafa;
    border: 1px solid #fafafa;
    border-radius: 8px;
    padding: 8px 16px;
    padding-top: 10px;
    margin-left: -10px;
    padding-left: 7px;
}

.container {
    margin-top: 20px;
}

.title-div {
    margin-bottom: 20px;
}

.title {
    font-size: 36px;
    font-weight: 700;
}

.extra {
    -webkit-text-decoration: None;
            text-decoration: None;
    color:#fafafa;
    pointer-events: none;
}

.announcements {
    margin-bottom: 20px;
}

.week-div {
    display: block;
    margin-bottom: 50px;
}

.wrapper {
    display: flex;
    height: 30px;
}

.current-week {
    margin-bottom: 0px;
    font-size: 16px;
    font-style: italic;
}

.button {
    text-decoration: none;
    display:inline-block;
    padding: 0.25rem;
    line-height: 1;
    margin-left: 10px;
    margin-top: 15px;
    border:0.1em solid black;
    border-radius:0.2rem;
    font-weight: 400;
    font-size: .875rem;
    color:black;
    background-color:#FFFFFF;
    text-align:center;
    height: 50%;
    transition: all 0.2s;
}


.button:hover {
    color:white;
    background-color:rgb(44, 44, 44);
}

.times {
    font-size: 1rem;
}



.body-container { 
    margin-left: 42px;
    margin-top: 30px;
    width: 700px;  
}


.announcements {
    margin-bottom: 20px;
}

.week {
    font-size: 16px;
    font-weight: 400;
    color: #888;
    margin-top: 18px;
    width: 115px;
}

.wrapper {
    margin-bottom: 10px;
}

.btn_arrow {
    font-size: 14px;
    margin-left: 6px;
    margin-bottom: 20px;
}

.button {
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
    padding: 6px 14px;
    line-height: 1;
    border: 1px;
    border-radius: 0px;
    font-weight: 400;
    font-size: 10pt;
    text-align:center;
    transition: all 0.2s;
    align-items: center;
}

.slides_button {
    background: #000;
    color: #fff;
}

.slides_button:hover {
    background: #444;
    border: #555;
}

.worksheet_button {
    background: #fff;
    color: #000;
    border: none;
}

.worksheet_button:hover  {
    background: #eee;
    color: #000;
    border: #ddd;
}

.btn_label {
    margin-right: 6px;
}




.App {
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.page {
  display: flex;
  width: 1000px;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.App-link {
  color: #61dafb;
}
